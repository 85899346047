@import '../../../../scss/theme-bootstrap';

.sitewide-ie-upgrade {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  html.ie8 &,
  html.ie7 &,
  html.ie6 & {
    background-color: $color-black;
  }
  &__content {
    z-index: 10001;
    background-color: $color-white;
    color: $color-black;
    width: 60%;
    max-width: 720px;
    top: 50%;
    left: 50%;
    position: relative;
    transform: translateX(-50%) translateY(-50%);
    html.ie8 &,
    html.ie7 &,
    html.ie6 & {
      left: 20%;
      top: 20%;
    }
    &-copy {
      padding: 25px 30px;
      text-align: center;
      .button {
        color: $color-white;
        &:hover {
          color: $color-white;
        }
      }
    }
    &-title {
      color: $color-black;
      font-size: 24px;
      @include breakpoint($medium-up) {
        font-size: 26px;
      }
    }
  }
  &__close {
    color: $color-black;
    position: absolute;
    top: 8px;
    right: 10px;
    display: block;
    font-size: 22px;
    cursor: pointer;
  }
}
